import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PageHeader from '../../components/header/page-header'
import Layout from '../../components/layout'

class JobInquiry extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      jobTitle: ''
    }
  }

  componentDidMount () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    // jobTitle
    const jobTitle = urlParams.get('Job')

    this.setState({
      jobTitle
    })
  }

  render () {
    return (
      <StaticQuery
        query={query}
        render={data => {
          return (
            <Layout bgColor>
              <PageHeader
                title={this.state.jobTitle}
                background={data.bgImage.childImageSharp.fluid}
                className="pink"
                thisPage={this.state.jobTitle}
                previousPage="Careers"
                previousPageLink="/careers"
              />
              <div></div>
            </Layout>
          )
        }}
      ></StaticQuery>
    )
  }
}

const query = graphql`
  query JobInquiry {
    bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default JobInquiry
